import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppImages } from "../../assets";
import FieldInput from "../../components/FieldInput";
import { FileTypes } from "../../utilities/constants";
import { pickFile } from "../../utilities";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from 'react-bootstrap/Dropdown';
import { createService, updateService } from "../../redux/actions/servicesActions";
import { selectImageUpload } from "../../redux/slices/imageUploadSlice";
import { uploadImageAction } from "../../redux/actions/imageUploadActions";
import { fetchImage } from "../../api/imageUpload.api";
import { selectSubCategories } from "../../redux/slices/subCategorySlice";
import { selectCategories } from "../../redux/slices/categorySlice"
import { selectProfessions } from "../../redux/slices/professionsSlice";
import { fetchProfessions } from "../../redux/actions/professionsActions";
import DropdownSelector from "../../components/DropDownSelector";
import { fetchCategories } from "../../redux/actions/categoryActions";
import { fetchSubCategories } from "../../redux/actions/subCategoryAction";

function CoverImage({ coverPhoto, onChangeCoverPicture }) {
  // const { loading, error, image } = useSelector(selectImageUpload);
  // const dispatch = useDispatch();

  const [image, setImage] = useState(null);

  useEffect(() => {
    console.log("coverPhoto", coverPhoto);
    if (coverPhoto) {
      setImage(coverPhoto)
    }
  }, [coverPhoto]);


  return (
    <div className="flex overflow-hidden relative flex-col items-end justify-end px-16 pt-20 pb-8 font-semibold tracking-widest text-center text-white min-h-[327px] bg-[#EEEEEE] max-md:px-5 max-md:max-w-full">
      {image ? (
        <img
          loading="lazy"
          src={image || AppImages.Placeholder}
          alt="Cover Image"
          className="object-cover absolute inset-0 size-full"
        />
      ) : (
        <p>Loading...</p>
      )}
      <button
        onClick={onChangeCoverPicture}
        className="absolute bottom-0 right-0 justify-center px-4 py-3 mr-4 mb-3 rounded-xl bg-[linear-gradient(276deg,#F0BA47_8.16%,#F3D25C_63.6%)] max-md:px-5 max-md:mt-10"
      >
        Change Picture
      </button>
    </div>
  );
}

function ProfileImage({ profilePicture, onChangeProfilePicture }) {
  // const { loading, error, image } = useSelector(selectImageUpload);
  // const dispatch = useDispatch();

  const [image, setImage] = useState(null);

  useEffect(() => {
    console.log("profilePicture", profilePicture);
    if (profilePicture) {
      setImage(profilePicture)}
  }, [profilePicture]);

  return (
    <div className="flex gap-3 justify-between self-start mt-16 font-semibold tracking-widest text-center text-white max-md:mt-10">
      {image ? (
        <img
          loading="lazy"
          src={image || AppImages.Placeholder}
          alt="Profile Image"
          className="shrink-0 max-w-full rounded-full border-solid aspect-square border-[7px] border-zinc-100 w-[149px]"
        />
      ) : (
        <p>Loading...</p>
      )}
      <button
        onClick={onChangeProfilePicture}
        className="justify-center px-3 py-3 my-auto rounded-xl bg-[linear-gradient(276deg,#F0BA47_8.16%,#F3D25C_63.6%)]"
      >
        Change Picture
      </button>
    </div>
  );
}

function AddEditService() {
  const location = useLocation();
  const service = location.state;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [category, setCategory] = useState(null)
  const [subCategory, setSubCategory] = useState(null)
  const [profession, setProfession] = useState(null);


  const { loading, error, imageURL } = useSelector(selectImageUpload);

  const [serviceName, setServiceName] = useState("");
  const [description, setDescription] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [profilePicture, setProfilePicture] = useState(null);
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [profilePictureURL, setProfilePictureURL] = useState(null);
  const [coverPhotoURL, setCoverPhotoURL] = useState(null);

  const { subCategories } = useSelector(selectSubCategories);
  const { categories } = useSelector(selectCategories);
  const { professions } = useSelector(selectProfessions);

  useEffect(() => {
    if (service) {
      setServiceName(service.name);
      setDescription(service.description);
      setCoverPhotoURL(service.image);
      setProfilePictureURL(service.icon);
      setIsActive(service.isActive);
      // setProfession(service.profession )
      debugger;
      setSubCategory(subCategories.filter(x=>x.id == service.subCategory)[0])
      fetchImage(service.image)
      .then((img) => setCoverPhoto(img))
      fetchImage(service.icon)
      .then((img) => setProfilePicture(img))
    }
  }, [service]);

  useEffect(() => {
    dispatch(fetchCategories())
        dispatch(fetchSubCategories())
    if (!professions || professions.length === 0) dispatch(fetchProfessions());
    
  }, [dispatch]);
  useEffect(() => {
    
    if (
      professions &&
      professions.length > 0 && service
    ) {
      const foundProfession = professions.find(
        (p) => p._id === service.profession
      );
      
      setProfession(foundProfession);
    }
    
  }, [professions, service]);

  const handleServiceNameChange = (e) => {
    setServiceName(e.target.value);
  };

 

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  // Function to handle toggle of the switch
  const handleActiveToggle = () => {
    setIsActive((prevIsActive) => !prevIsActive);
  };

  const handleChangeCoverPicture = () => {
    pickFile([FileTypes.IMAGE], async (result) => {
      dispatch(uploadImageAction("categories", "cover", result,
        (path)=>{
          setCoverPhotoURL(path)
          setCoverPhoto(URL.createObjectURL(result))
        }
      ))
      dispatch(uploadImageAction("categories", "icon", result,
        (path)=>{
          setProfilePictureURL(path)
          setProfilePicture(URL.createObjectURL(result))
        }
      ))
    });
  };

  

  const handleSave = () => {

  
    const updatedServiceData = {};
  
    // Check for each field if it's been updated, and if so, add it to the updatedServiceData
    if (service?.name !== serviceName) {
      updatedServiceData.name = serviceName;
    }

    if (!serviceName || !description ||!coverPhoto||!profilePicture ||!subCategory) {
      alert("Please fill in all fields.");
      return;
    }
  
    updatedServiceData.name = serviceName;
    updatedServiceData.isActive = isActive;
    updatedServiceData.description = description;
    updatedServiceData.image = coverPhotoURL;
    updatedServiceData.icon = profilePictureURL;
    updatedServiceData.subCatId = subCategory.id;
    updatedServiceData.profession = profession._id
    debugger;
    console.log("Save me", updatedServiceData);
  
    if (service) {
      // Update existing service
      updatedServiceData.id = service.id;
      dispatch(updateService(updatedServiceData));
    } else {
      // Create new service
      dispatch(createService({
        name: serviceName,
        isActive: isActive,
        description: description,
        image: coverPhotoURL,
        icon: profilePictureURL,
        subCatId: subCategory.id,
        profession : profession._id
      }));
    }
    navigate(-1)
  };
  
  const subCategorySelected = (obj) => {
    setSubCategory(obj)
  }
  const categorySelected = (obj) =>{
    setCategory(obj)
  }
  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <section className="flex flex-col px-8 py-8 text-sm bg-white rounded-3xl shadow-sm max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <CoverImage
        coverPhoto={coverPhoto}
        onChangeCoverPicture={handleChangeCoverPicture}
      />
      
      <div className="flex gap-5 items-start mt-3  tracking-widest max-md:flex-wrap max-md:max-w-full">
      <div className="flex flex-col items-start mt-3 text-sm font-medium tracking-widest max-md:flex-wrap max-md:max-w-full">
          <label
            htmlFor="Category"
            className="text-black font-metropolis text-base font-normal leading-normal tracking-wide"
          >
            Category
          </label>
          <div className=" grow justify-center items-start px-1 py-2 mt-1.5 w-half rounded-2xl border border-solid border-zinc-400 w-fit max-md:px-5 max-md:max-w-full">
            <Dropdown className="btn-dropdown-halo w-full h-full px-2 py-1 text-black font-metropolis text-sm font-semibold tracking-wide leading-normal border-none outline-none">
              <Dropdown.Toggle className=" btn-dropdown-halo w-full h-full px-2 py-1 text-black font-metropolis text-sm font-semibold tracking-wide leading-normal border-none outline-none" variant="success" id="dropdown-basic">
                {category == null ? 'Select Category' : category.name}
              </Dropdown.Toggle>

              <Dropdown.Menu className="btn-dropdown-list-halo">
                {categories.filter(x=>x.isActive==true).map((category, index) => (
                  <Dropdown.Item onClick={
                    () => {
                      categorySelected(category);
                    }

                  } >{category.name}</Dropdown.Item>

                ))}

              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="flex flex-col items-start mt-3 text-sm font-medium w-full tracking-widest max-md:flex-wrap max-md:max-w-full">
          <label
            htmlFor="Category"
            className="text-black font-metropolis text-base font-normal leading-normal tracking-wide"
          >
            Sub Category
          </label>
          <div className=" grow justify-center items-start px-1 py-2 mt-1.5 w-half rounded-2xl border border-solid border-zinc-400 w-fit max-md:px-5 max-md:max-w-full">
            <Dropdown className="btn-dropdown-halo w-full h-full px-2 py-1 text-black font-metropolis text-sm font-semibold tracking-wide leading-normal border-none outline-none">
              <Dropdown.Toggle className=" btn-dropdown-halo w-full h-full px-2 py-1 text-black font-metropolis text-sm font-semibold tracking-wide leading-normal border-none outline-none" variant="success" id="dropdown-basic">
                {subCategory == null ? 'Select Sub Category' : subCategory.name}
              </Dropdown.Toggle>

              <Dropdown.Menu className="btn-dropdown-list-halo">
                {subCategories.filter(x=>x.isActive==true).filter(x=>x.category==category?.id).map((subCategory, index) => (
                  <Dropdown.Item onClick={() => {subCategorySelected(subCategory);}

                  } >{subCategory.name}</Dropdown.Item>

                ))}

              </Dropdown.Menu>
            </Dropdown>
          </div>
          
        </div>
        {professions && (
          <DropdownSelector
            label="Profession"
            items={professions.filter(x=>x.isActive==true)}
            selectedItem={profession}
            onSelect={setProfession}
          />
        )}
      </div>
      <div className="flex gap-5 items-start mt-3  tracking-widest max-md:flex-wrap max-md:max-w-full">
        <FieldInput
          label="Service Name"
          value={serviceName}
          onChange={handleServiceNameChange}
          placeholder="Enter service name"
        />
        <FieldInput
        label="Active/Inactive"
        value={isActive}
        onChange={handleActiveToggle}
        type="switch"
        placeholder="Active"
      />
      </div>
      <FieldInput
        label="Description"
        value={description}
        onChange={handleDescriptionChange}
        placeholder="Enter description"
        type="textarea"
      />
      
      <div className="flex gap-4 mt-16 ml-2.5 max-w-full text-sm font-bold text-white whitespace-nowrap tracking-[2px] w-[303px] max-md:mt-10">
        <button
          onClick={handleSave}
          className="justify-center items-start px-4 py-3 rounded-xl bg-[linear-gradient(276deg,#F0BA47_8.16%,#F3D25C_63.6%)] max-md:px-5"
        >
          {service ? "Save" : "Add Service"}
        </button>
        <button
          onClick={handleCancel}
          className="justify-center px-4 py-3 bg-rose-500 rounded-xl max-md:px-5"
        >
          Cancel
        </button>
      </div>
    </section>
  );
}

export default AddEditService;
